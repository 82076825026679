const DadosDeProjetos = [
    {
        id : 1,
        foto : 'Dragons',
        tituloEn: 'Project - Dragons Guide',
        tituloPT: 'Projeto - Guia de Dragões',
        TextoEn: 'Project created to bring a sense of creativity on how to make a small project look nice',
        TextoPT: 'Projeto criado para trazer um pouco da sensação de criatividade sobre como fazer um pequeno projeto ser legal',
        Link: 'https://dragons-guide.vercel.app',
        JS : true,
        CSS : true,
        HTML : true,
        REACT : true, 
    },
    {
        id : 2,
        foto : 'Animais',
        tituloEn: 'Project - Animals',
        tituloPT: 'Projeto - Animais',
        TextoEn: 'Project created by Lun Dev, developed by my self with high visual feedback for the user',
        TextoPT: 'Projeto proposto pela Lun Dev, desenvolvido por mim com um alto retorno visual para o usuario',
        Link: 'https://animals-hewut8cec-cozacs-projects.vercel.app',
        JS : true,
        CSS : true,
        HTML : true,
        REACT : true, 
    },
    {
        id : 3,
        foto : 'Wolf',
        tituloEn: 'Project - Wolf',
        tituloPT: 'Projeto - Lobo',
        TextoEn: 'Project to practice some CSS animations on the loading page',
        TextoPT: 'Projeto para exercitar um pouco de animações em CSS ao carregar a pagina',
        Link: 'https://wolf-moon-azure.vercel.app',
        JS : true,
        CSS : true,
        HTML : true,
        REACT : true, 
    },
    {
        id : 4,
        foto : 'Sorveteria',
        tituloEn: 'Project - Sorveteria',
        tituloPT: 'Projeto - Sorveteria',
        TextoEn: 'Project created by DevMedia, developed by my self using the technologies below',
        TextoPT: 'Projeto proposto pela DevMedia, desenvolvido por mim utilizando as tecnologias abaixo',
        Link: 'https://sorveteria-alpha.vercel.app',
        JS : true,
        CSS : true,
        HTML : true,
        REACT : true,
    },
    {
        id : 5,
        foto : 'Cordel',
        tituloEn: 'Project - Cordel',
        tituloPT: 'Projeto - Cordel',
        TextoEn: 'Project created by CursoEmVideo, developed by my self when i started to study development using the technologies below',
        TextoPT: 'Projeto proposto pelo CursoEmVideo, desenvolvido por mim, no começo dos meus estudos utilizando as tecnologias abaixo',
        Link: 'https://cozacs.github.io/ProjetoCordel/',
        JS : false,
        CSS : true,
        HTML : true,
        REACT : false,
    },{
        id : 6,
        foto : 'Analizador',
        tituloEn: 'Project - Analize',
        tituloPT: 'Projeto - Analizador',
        TextoEn: 'Project created by CursoEmVideo, developed by my self when i started to develop in JavaScript',
        TextoPT: 'Projeto proposto pela CursoEmVideo, desenvolvido por mim  quando começei a utilizar o JavaScript',
        Link: 'https://cozacs.github.io/Analisador-de-Numeros/',
        JS : true,
        CSS : true,
        HTML : true,
        REACT : false,
    },{
        id : 7,
        foto : 'QualIdade',
        tituloEn: 'Project - How Old r U?',
        tituloPT: 'Projeto - Qual a minha idade?',
        TextoEn: 'Project created by CursoEmVideo, developed by my self with high visual feedback for the user',
        TextoPT: 'Projeto proposto pela CursoEmVideo, desenvolvido por mim com um alto retorno visual para o usuario',
        Link: 'https://cozacs.github.io/Verificador-de-Idade/',
        JS : true,
        CSS : true,
        HTML : true,
        REACT : false,
    }
];

export default DadosDeProjetos;